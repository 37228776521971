import { Questions } from '../types'

export const QUESTIONS: Questions = [{
  question: 'Idag förvaltar de cirka 60 medlemmarna i Net-Zero Asset Owner Alliance mer än 85 biljoner kronor. Ja, det står BILJONER. Vilket svenskt bolag var en av sex grundare 2019?',
  answers: [
    { text: 'Någon av de stora bankerna', isCorrect: false },
    { text: 'Kanske något statligt', isCorrect: false },
    { text: 'Folksam så klart. Boom!', isCorrect: true },
  ],
}, {
  question: 'När började Folksam engagera sig i miljörörelsen egentligen?',
  answers: [
    { text: 'När Greta Thunberg blev berömd', isCorrect: false },
    { text: '1967 – innan miljörörelsen föddes', isCorrect: true },
    { text: 'Folksam bryr sig inte om miljön', isCorrect: false },
  ],
}, {
  question: 'Naturskyddsföreningen har en av världens tuffaste miljömärkningar: grön pilgrimsfalk med texten ”Märkt med Bra Miljöval”. Hur många miljömärkta försäkringar har Folksam?',
  answers: [
    { text: 'Två', isCorrect: false },
    { text: 'En', isCorrect: false },
    { text: 'Fyra', isCorrect: true },
  ],
}, {
  question: 'Folksam var en av huvudsponsorerna till världens första klimatkonferens. Vilket år var det?',
  answers: [
    { text: '1999', isCorrect: false },
    { text: '1989', isCorrect: false },
    { text: '1972', isCorrect: true },
  ],
}, {
  question: 'Vilka språk kan du få hjälp på i Folksams kundtjänst?',
  answers: [
    { text: 'Svenska, engelska och spanska', isCorrect: false },
    { text: 'Svenska, arabiska, engelska och spanska', isCorrect: false },
    { text: 'Svenska, arabiska, bosniska, kroatiska, engelska, finska, tyska, nordkurdiska, sydkurdiska, pashto/dari, persiska, polska, ryska serbiska, spanska och turkiska', isCorrect: true },
  ],
}, {
  question: 'Hur många miljarder förvaltar Folksam åt sina många kunder?',
  answers: [
    { text: 'Det finns väl inga miljarder i Folksam?!', isCorrect: false },
    { text: 'Minst 400 miljarder kronor', isCorrect: false },
    { text: '750 miljarder kronor är bara början', isCorrect: true },
  ],
}, {
  question: 'Hur många svenskar har minst en försäkring eller ett sparande hos Folksam?',
  answers: [
    { text: '2 miljoner borde det vara', isCorrect: false },
    { text: '675000 verkar rätt', isCorrect: false },
    { text: '4,4 miljoner', isCorrect: true },
  ],
}, {
  question: 'Vad gillar du bäst?',
  answers: [
    { text: 'Folksamlaget', isCorrect: true },
    { text: 'Folksamtalet', isCorrect: true },
    { text: 'Folksamlingen', isCorrect: true },
  ],
}]

export const VARIANTS: string[] = ['A', 'B', 'C']
