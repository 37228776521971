// Core
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
// Pages
import { Quiz, Result } from '../pages'

export const PersonalRoutes: React.FC = () => (
  <Routes>
    <Route path="/questions/:question" element={<Quiz />} />
    <Route path="/result" element={<Result />} />
    <Route path="/*" element={<Navigate replace to="/questions/1" />} />
  </Routes>
)
