// Core
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
// Components
import { Button } from '../../components/common'
import logo from '../../assets/images/logo.png'
// Other
import { hasStringHTMLTags } from '../../utils/helpers'
// Styles
import styles from './auth.module.sass'
import logoFolksam from '../../assets/images/logo_Folksam.png'

interface IAuthFormInputs {
  firstLastNames?: string;
  email?: string;
}

const authFormSchema = yup.object({
  firstLastNames: yup.string()
    .required('Please fill out the field')
    .max(64, 'Please enter less or equal to 64 characters')
    .test('firstLastNames', 'Field shouldn\'t contain "<..."', (value?: string) => hasStringHTMLTags(value)),
  email: yup.string()
    .email('Please enter a valid email')
    .required('Please fill out the field')
    .max(64, 'Please enter less or equal to 64 characters')
    .test('email', 'Field shouldn\'t contain "<..."', (value?: string) => hasStringHTMLTags(value)),
})

export const Auth: React.FC = () => {
  const [isAgreed, setIsAgreed] = useState<boolean>()
  const {
    register, handleSubmit, formState: { errors, isSubmitting }, setFocus,
  } = useForm<IAuthFormInputs>({
    resolver: yupResolver(authFormSchema),
  })
  const navigate = useNavigate()

  useEffect(() => {
    setFocus('firstLastNames')
  }, [setFocus])

  const handleCheckboxChange = () => {
    // eslint-disable-next-line no-unneeded-ternary
    setIsAgreed((prevAgreed) => (prevAgreed === true ? false : true))
  }

  const onSubmit = (data: IAuthFormInputs) => {
    if (localStorage) {
      localStorage.setItem('isQuizStarted', 'true')
      localStorage.setItem('quizName', data.firstLastNames ?? '')
      localStorage.setItem('quizEmail', data.email ?? '')
    }
    setIsAgreed(undefined)
    navigate('/questions/1', { replace: true })
    navigate(0)
  }

  return (
    <div className={styles.auth}>
      <div>
        <img className="logo" src={logo} alt="Folksam quiz logo" />
        <div className={styles.auth__content}>
          <h1>Dina personuppgifter</h1>
          <p className={styles.auth__text}>
            Vi behandlar dina personuppgifter i enlighet med Dataskyddsförordningen GDPR.
            Genom undertecknande bekräftas att jag godkänner detta.
            Efter att tävlingen är avslutad raderas insamlade personuppgifter.
            Läs mer på folksam.se/personuppgifter.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <p>För och efternamn</p>
              <input
                type="text"
                required
                id="firstLastNames"
                maxLength={64}
                aria-invalid={errors.firstLastNames ? 'true' : 'false'}
                className={styles.auth__input}
                {...register('firstLastNames')}
              />
            </div>
            <p role="alert" className="error">{errors.firstLastNames?.message}</p>
            <div className="form-group">
              <p>Epost</p>
              <input
                type="email"
                id="email"
                required
                maxLength={64}
                aria-invalid={errors.email ? 'true' : 'false'}
                className={styles.auth__input}
                {...register('email')}
              />
            </div>
            <p role="alert" className="error">{errors.email?.message}</p>
            <div className={styles.auth__agreement}>
              <input
                onChange={handleCheckboxChange}
                type="checkbox"
                checked={!!isAgreed}
                aria-invalid={!isAgreed ? 'true' : 'false'}
                className={styles.auth__checkbox}
              />
              <p className={styles.auth__label}>
                Undertecknad godkänner att Folksam behandlar mina personuppgifter
              </p>
            </div>
            <p className="error" role="alert">{isAgreed === false ? 'The undersigned must agree.' : ''}</p>
            <Button disabled={!isAgreed || !!errors.firstLastNames?.message || !!errors.email?.message || isSubmitting} ariaLabel="Start quiz" isSubmitType>
              Starta!
            </Button>
          </form>
        </div>
      </div>
      <img className="logo-folksam" src={logoFolksam} alt="Folksam logo" />
    </div>
  )
}
