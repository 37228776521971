// Core
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
// Components
import logoFolksam from '../../assets/images/logo_Folksam.png'
import { Answer } from '../../components'
import { Button } from '../../components/common'
// Other
import { QUESTIONS, VARIANTS } from '../../utils/constants'
import { hasStringHTMLTags } from '../../utils/helpers'
// Styles
import styles from './quiz.module.sass'

interface ITextFieldForm {
  textValue?: string;
}

const textFieldFormSchema = yup.object({
  textValue: yup.lazy((value) => {
    if (value === '' || !value) return yup.string()
    return yup.string()
      .required('Please fill out the field')
      .max(350, 'Please enter less or equal to 350 characters')
      .test('textValue', 'Field shouldn\'t contain "<..."', (val?: string) => hasStringHTMLTags(val))
  }),
})

export const Quiz: FC = () => {
  const [chosenAnswer, setChosenAnswer] = useState<string>()
  const { question } = useParams()
  const {
    register, handleSubmit, formState: { errors, isSubmitting }, setFocus,
  } = useForm<ITextFieldForm>({ resolver: yupResolver(textFieldFormSchema) })
  const navigate = useNavigate()

  useEffect(() => {
    const questionLast: string | null = !!localStorage && localStorage.getItem('quizQuestion')
    if (!questionLast && !!localStorage) localStorage.setItem('quizResult', '0')
    if (!questionLast && Number(question) > 1) navigate('/questions/1', { replace: true })
    if (typeof questionLast === 'string') {
      if (Number(questionLast) < QUESTIONS.length + 2) {
        navigate(`/questions/${questionLast}`, { replace: true })
      } else {
        navigate('/result')
      }
    }
  }, [])

  useEffect(() => {
    if (Number(question) === QUESTIONS.length + 1) setFocus('textValue')
  }, [setFocus, question])

  const onSubmit = async (data: ITextFieldForm) => {
    try {
      const curResult: string | null = localStorage && localStorage.getItem('quizResult')
      if (localStorage) localStorage.setItem('quizQuestion', (Number(question) + 1).toString())
      if (Number(question) !== QUESTIONS.length + 1 && !!chosenAnswer) {
        setChosenAnswer(undefined)
        const chosenIdx = VARIANTS.indexOf(chosenAnswer)
        if (Number(question) <= QUESTIONS.length
          && QUESTIONS[Number(question) - 1].answers[chosenIdx].isCorrect) {
          if (!curResult && !!localStorage) {
            localStorage.setItem('quizResult', '1')
          } else {
            localStorage.setItem('quizResult', (Number(curResult) + 1).toString())
          }
        }
        navigate(`/questions/${Number(question) + 1}`, { replace: true })
      } else {
        const usersName: string | null = localStorage && localStorage.getItem('quizName')
        const usersEmail: string | null = localStorage && localStorage.getItem('quizEmail')
        await fetch('https://folksam-2022-kf4vxl73ya-lz.a.run.app/add', {
          method: 'POST',
          headers: { Accept: 'application/json' },
          body: JSON.stringify({
            name: usersName ?? '',
            email: usersEmail ?? '',
            score: curResult ? Number(curResult) : 0,
            feedback: data.textValue,
          }),
        })
        navigate('/result')
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  return (
    <div className={styles.quiz}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={styles.quiz__desc}>{`Fråga ${question} av ${QUESTIONS.length + 1}`}</p>
        <p className={styles.quiz__question}>
          {Number(question) <= QUESTIONS.length
            ? QUESTIONS[Number(question) - 1].question : 'Beskriv med max 25 ord varför Folksam är en bra samarbetspartner'}
        </p>
        {Number(question) <= QUESTIONS.length
        && QUESTIONS[Number(question) - 1].answers.map((a, idx) => (
          <Answer
            key={VARIANTS[idx]}
            index={idx}
            chosen={chosenAnswer}
            setChosen={setChosenAnswer}
            text={a.text}
          />
        ))}
        {Number(question) === (QUESTIONS.length + 1) && (
        <>
          <textarea
            required
            maxLength={351}
            defaultValue=""
            aria-invalid={errors.textValue ? 'true' : 'false'}
            rows={10}
            {...register('textValue')}
          />
          <p className="error" role="alert">{errors.textValue?.message}</p>
        </>
        )}
        <Button
          disabled={isSubmitting || (Number(question) !== QUESTIONS.length + 1 && !chosenAnswer)
          || !!errors.textValue?.message}
          ariaLabel={Number(question) !== QUESTIONS.length + 1 ? 'Next' : 'Finish'}
          isSubmitType
        >
          Nästa!
        </Button>
      </form>
      <img className="logo-folksam" src={logoFolksam} alt="Folksam logo" />
    </div>
  )
}
