// Core
import React, { MouseEvent, ReactNode } from 'react'
// Styles
import styles from './button.module.sass'

type ButtonProps = {
  onClick?: () => void;
  children: ReactNode;
  ariaLabel: string;
  disabled?: boolean;
  isSubmitType?: boolean;
}

const ButtonMemoized: React.FC<ButtonProps> = ({
  children, onClick, disabled = false, ariaLabel, isSubmitType = false,
}: ButtonProps) => {
  const handleBtnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (typeof onClick === 'function') onClick()
  }
  return (
    <button
      className={styles.button}
      onClick={handleBtnClick}
      aria-label={ariaLabel}
      disabled={!!disabled}
      type={isSubmitType ? 'submit' : 'button'}
    >
      {children}
    </button>
  )
}

ButtonMemoized.defaultProps = {
  disabled: undefined,
  isSubmitType: undefined,
  onClick: undefined,
}

export const Button = React.memo(ButtonMemoized)
