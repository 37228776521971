// Core
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
// Routes
import { AuthRoutes } from '../routes/Auth'
import { PersonalRoutes } from '../routes/Personal'

const isStarted: string | null = localStorage.getItem('isQuizStarted') || null

const App: React.FC = () => (
  <main role="main">
    <BrowserRouter>{isStarted === 'true' ? <PersonalRoutes /> : <AuthRoutes />}</BrowserRouter>
  </main>
)

export default App
