// Core
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
// Pages
import { Auth, Home } from '../pages'

export const AuthRoutes: React.FC = () => (
  <Routes>
    <Route index element={<Home />} />
    <Route path="/auth" element={<Auth />} />
    <Route path="/*" element={<Navigate replace to="/auth" />} />
  </Routes>
)
