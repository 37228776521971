import React, { Dispatch, FC, SetStateAction } from 'react'
import { VARIANTS } from '../../utils/constants'
import styles from './answer.module.sass'

type AnswerProps = {
  index: number;
  setChosen: Dispatch<SetStateAction<string | undefined>>;
  text: string;
  chosen?: string;
}

const AnswerMemoized: FC<AnswerProps> = ({
  index, chosen, setChosen, text,
}: AnswerProps) => {
  const handleAnswerClick = () => {
    setChosen(VARIANTS[index])
  }

  return (
    <button
      aria-label="Answer"
      type="button"
      onClick={handleAnswerClick}
      className={styles.answer}
    >
      <div className={styles.answer__variant}>
        <span>{VARIANTS[index]}</span>
        <p className={chosen !== VARIANTS[index] ? styles.answer__text : styles['answer__text-chosen']}><span>{text}</span></p>
      </div>
    </button>
  )
}

AnswerMemoized.defaultProps = {
  chosen: undefined,
}
export const Answer = React.memo(AnswerMemoized)
