// Core
import React, { useRef, MutableRefObject, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// Components
import logo from '../../assets/images/logo.png'
import logoFolksam from '../../assets/images/logo_Folksam.png'
// Other
import { QUESTIONS } from '../../utils/constants'
// Styles
import styles from './result.module.sass'

export const Result: React.FC = () => {
  const result: MutableRefObject<false | string | null> = useRef(!!localStorage
      && localStorage.getItem('quizResult'))
  const navigate = useNavigate()

  useEffect(() => {
    const questionLast: string | null = !!localStorage && localStorage.getItem('quizQuestion')
    if (!questionLast) navigate('/questions/1', { replace: true })
    if (typeof questionLast === 'string' && Number(questionLast) < QUESTIONS.length + 2) {
      navigate(`/questions/${questionLast}`, { replace: true })
    }
  }, [])

  const outputTitle = (res: string | false | null): string => {
    if (typeof res === 'string' && res.length > 0) {
      return +res < 6 ? 'Bra försök!' : 'Bra jobbat!'
    }
    return 'Bra försök!'
  }

  return (
    <div className={styles.result}>
      <div className={styles.result__content}>
        <img className="logo" src={logo} alt="Folksam quiz logo" />
        <h1>{outputTitle(result.current)}</h1>
        <p className={styles.result__text}>Ditt resultat:</p>
        <p className={styles.result__text}>
          <span className={styles.result__amount}>{result.current || '0'}</span>
          {' '}
          av
          {' '}
          <span className={styles.result__amount}>{QUESTIONS.length}</span>
        </p>
      </div>
      <img className="logo-folksam" src={logoFolksam} alt="Folksam logo" />
    </div>
  )
}
