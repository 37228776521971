// Core
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
// Components
import { Button } from '../../components/common'
import logo from '../../assets/images/logo.png'
import cloud from '../../assets/images/cloud.png'
// Other
import logoFolksam from '../../assets/images/logo_Folksam.png'
// Styles
import styles from './home.module.sass'

export const Home: FC = () => {
  const navigate = useNavigate()
  const handleStartClick = () => navigate('/auth')

  return (
    <div className={styles.start}>
      <div>
        <div className={styles.start__logo}>
          <img src={logo} alt="Folksam quiz logo" />
          <img src={cloud} alt="Quiz" />
        </div>
        <h1 className={styles.start__text}>
          Sök i ditt inre efter sanningen,
          undvik fördomarna och satsa allt på ”det hade jag ingen aning om”.
          <br />
          Det finns priser och de är fina.
        </h1>
        <Button
          ariaLabel="Start"
          onClick={handleStartClick}
        >
          Starta!
        </Button>
      </div>
      <img className="logo-folksam" src={logoFolksam} alt="Folksam logo" />
    </div>
  )
}
